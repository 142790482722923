/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #media-control {background: red;}
  header{padding: 10px 20px 0px 20px;}

  #menu-list{display: none;}
  /* Presentation */
  #presentation{padding: 60px 20px 0px 0px;}
  .soft {font-size: 2.5rem;padding-top: 20px;}
  .p-buts>button{font-size: 16px;}
  .summary {width: 95%;margin-top: 15px;}
  .summary>h1:nth-child(1){font-size: 18px;margin-top: 15px;margin-bottom: 0px;}
  .summary>h1:nth-child(2){font-size: 14px;font-weight: 500;}
  #presentation>img:nth-child(1){height: 20%;}
  .p-buts>button{padding: 10px;}
  .p-buts{ display: flex;flex-direction: column;}
  .p-buts>button:nth-child(1){margin-right: 0px;margin-bottom: 15px;}

  /* About */
  #about{justify-content: start;padding: 60px 20px 0px 20px;}
  .ab-text{font-size: 12px;}
  .sk-head>h4 {font-size: 12px;}
  .sk-body{transform: scale(0.6);}
  #labels>div>h4{font-size: 12px;top: -44px;margin-left: 46px;}
  .clipath{width: 160px;height: 40px;}
  .education>h2{font-size: 18px;}
  .education>h3{font-size: 16px;}
  #about>div:nth-child(2) {
    flex-direction: column;
    height: auto;
  }
  #labels {flex-direction: row;flex-wrap: wrap;}
  #labels>div {height: 50px;}
  .bar-about{margin-top: 25px;}
  #about-contant{justify-content: center;}
  #about-contant>div:nth-child(1) {width: 100%;margin-top: 15px;}
  
  /* Projects */
  #projects{padding: 60px 20px 0px 20px;background-size: 70%;}
  .swiper {width: 90%;height: 50%;}
  .card-desc{font-size: 14px;}
  .card-git{right: 20px;}
  .card-tech>span{font-size: 12px;}
  .card-title {font-size: 1rem;}
  #card-link{font-size: 14px;}
  .card-logo {height: 35px;}
  .procard{padding-left: 24px;}
  /* Contact */
  #contact {
      flex-direction: column;
      padding: 60px 20px 0px 20px;
      justify-content: space-around;
  }
  .c-text>h1 {margin: 0; font-size: 28px;}
  .c-text {width: 100%;}
  .ctxtbr{display: none;}
  #contact>div:nth-child(2) {width: 100%;}
  .footer{display: none;}
  .footer2{display: flex;flex-direction: column; color: white; align-items: center;}

}
  
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #media-control {background: green;}
  #menu-list{display: none;}
  header{padding: 10px 20px 0px 20px;}
  /* Presentation */
  #presentation{padding: 60px 20px 0px 0px;}
  .soft {font-size: 3rem;padding-top: 15px;}
  .p-buts>button{font-size: 16px;}
  .summary {width: 70%;margin-top: inherit;}
  .summary>h1:nth-child(1){font-size: 18px;margin-top: 15px;margin-bottom: 0px;}
  .summary>h1:nth-child(2){font-size: 14px;font-weight: 500;}
  #presentation>img:nth-child(1){height: 40%;}
  .p-buts>button{padding: 10px;}
  .p-buts{ display: flex;flex-direction: column;}
  .p-buts>button:nth-child(1){margin-right: 0px;margin-bottom: 15px;}
  
  /* About */
  #about{justify-content: start;padding: 60px 20px 0px 20px;}
  .ab-text{font-size: 14px;}
  .sk-head>h4 {font-size: 14px;}
  .sk-body{transform: scale(0.7);}
  #labels>div>h4{font-size: 12px;top: -44px;margin-left: 46px;}
  .clipath{width: 160px;height: 40px;}
  .education>h2{font-size: 20px;}
  .education>h3{font-size: 18px;}
  #about>div:nth-child(2) {
    flex-direction: column;
    height: auto;
  }
  #labels {flex-direction: row;flex-wrap: wrap;}
  #labels>div {height: auto;}
  .bar-about{margin-top: 25%;}
  #about-contant{justify-content: center;}

  /* Projects */
  #projects{padding: 60px 20px 0px 20px;background-size: 50%;}
  .swiper {width: 90%;height: 60%;}
  .card-desc{font-size: 14px;}
  .card-git{right: 20px;}
  .card-tech>span{font-size: 14px;}
  .card-title {font-size: 2.2rem;}
  #card-link{font-size: 14px;}
  .card-logo {height: 35px;}

  /* Contact */
  #contact{padding: 60px 20px 0px 20px;}
  .c-text>h1 {font-size: 3rem;}

}
  
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #media-control {background: blue;}
  #menu-list{display: flex;}
  header{padding: 10px 40px 0px 40px;}

  /* Presentation */
  #presentation{padding: 60px 40px 0px 0px;}
  .soft {font-size: 3rem;}
  .p-buts>button{font-size: 16px;}
  .summary {width: 70%;}
  .summary>h1:nth-child(1){font-size: 20px;margin-top: 25px;margin-bottom: 0px;}
  .summary>h1:nth-child(2){font-size: 14px;font-weight: 500;}
  #presentation>img:nth-child(1){height: 50%;}
  .p-buts>button{padding: 20px;}
  .p-buts{ display: flex;flex-direction: row;}
  .p-buts>button:nth-child(1){margin-right: 20px;margin-bottom: inherit;}

  /* About */
  #about{justify-content: center;padding: 60px 40px 0px 40px;}
  .ab-text{font-size: 16px;}
  .sk-head>h4 {font-size: 16px;}
  .sk-body{transform: scale(0.8);}
  #labels>div>h4{font-size: 14px;top: -52px;margin-left: 52px;}
  .clipath{width: 180px;height: 50px;}
  .education>h2{font-size: 24px;}
  #about>div:nth-child(2) {
    flex-direction: column;
    height: auto;
  }
  #labels {flex-direction: row;flex-wrap: wrap;}
  #labels>div {height: 60px;}
  .bar-about{margin-top: 25px;}
  #about-contant{justify-content: start;}
  #about-contant>div:nth-child(1) {width: 100%;margin-top: 15px;}

  /* Projects */
  #projects{padding: 60px 40px 0px 40px;background-size: 45%;}
  .swiper {width: 95%;height: 60%;}
  .card-desc{font-size: 16px;}
  .card-git{right: 20px;}
  .card-tech>span{font-size: 16px;}
  .card-title {font-size: 2.2rem;}
  #card-link{font-size: 16px;}
  .card-logo {height: 35px;}

  /* Contact */
  #contact{padding: 6px 40px 0px 40px;}
  
}
  
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #media-control {background: orange;}
  /* Presentation */
  #presentation{padding: 60px 40px 0px 0px;}
  .soft {font-size: 4rem;}
  .summary {width: 65%;}
  .summary>h1:nth-child(1){font-size: 20px;margin-top: 25px;margin-bottom: 0px;}
  .summary>h1:nth-child(2){font-size: 14px;font-weight: 500;}
  .p-buts>button{font-size: 16px;}
  #presentation>img:nth-child(1){height: 55%;}
  .p-buts>button{padding: 20px;}

  /* About */
  #about{justify-content: center;}
  .ab-text{font-size: 14px;}
  .sk-head>h4 {font-size: 14px;text-align: center;}
  .sk-body{transform: scale(0.7);}
  #labels>div>h4{font-size: 14px;top: -52px;margin-left: 52px;flex-wrap: nowrap;}
  .clipath{width: 180px;height: 50px;}
  .education>h2{font-size: 28px;}
  .education>h3{font-size: 18px;}
  #about>div:nth-child(2) {flex-direction: row;height: 60%;}
  #labels {flex-direction: column;flex-wrap: nowrap;}
  #labels>div {height: 60px;}
  .bar-about{margin-top: inherit;}
  #about-contant{justify-content: end;}
  #about-contant>div:nth-child(1) {width: 90%;margin-top: inherit;}

  /* Project */
  #projects{padding: 60px 40px 0px 40px;background-size: 45%;}
  .swiper {width: 70%;height: 60%;}
  .card-desc{font-size: 18px;}
  .card-git{right: 20px;}
  .card-tech>span{font-size: 20px;}
  .card-title {font-size: 2.2rem;}
  #card-link{font-size: 18px;}
  .card-logo {height: 40px;}
  /* Contact */
  #contact{padding: 6px 40px 0px 40px;}
  .c-text>h1 {font-size: 4rem;}

}
  
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #media-control {background: pink;}
  header{padding: 10px 80px 0px 80px;}
  /* Presentation */
  #presentation{padding: 60px 80px 0px 0px;}
  .soft {font-size: 8rem;}
  .summary {width: 50%;}
  .summary>h1:nth-child(1){font-size: 28px;margin-top: 25px;margin-bottom: 0px;}
  .summary>h1:nth-child(2){font-size: 18px;font-weight: 500;}
  .p-buts>button{font-size: 16px;}
  #presentation>img:nth-child(1){height: 65%;}
  .p-buts>button{padding: 16px;}

  /* About */
  #about{justify-content: center;padding: 60px 80px 0px 80px;}
  .ab-text{font-size: 20px;}
  .sk-head>h4 {font-size: 20px;padding-bottom: 25px;  }
  .sk-body{transform: scale(1);}
  #labels>div>h4{font-size: 16px;top: -60px;margin-left: 60px;}
  .clipath{width: 200px;height: 60px;}
  .education>h2{font-size: 48px;}
  .education>h3{font-size: 28px;}
  #about>div:nth-child(2) {flex-direction: row;}
  #labels {flex-direction: column;flex-wrap: nowrap;}
  #labels>div {height: 90px;}
  .bar-about{margin-top: inherit;}
  #about-contant{justify-content: end;}

  /* Project */
  #projects{padding: 60px 80px 0px 80px;background-size: 35%;}
  .swiper {width: 60%;height: 60%;}
  .card-desc{font-size: 20px;}
  .card-git{right: 20px;}
  .card-tech>span{font-size: 1.2rem;}
  .card-title {font-size: 2.2rem;}
  #card-link{font-size: 18px;}
  .card-logo {height: 40px;}
  .procard{padding-left: 50px;}
  /* END */

/* Contact */
#contact{
  display: flex;
  flex-direction: row;
  padding: 60px 80px 0px 80px;
  justify-content: space-between;
  align-items: center;
  color: #100c3e!important;
  background-image: url('./../public/assets/img/me.svg');
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 50%;
}
.c-text{
  display: flex;
  flex-direction: column;
  color: white;
}
.c-text>h1{
  font-size: 4rem;
}
.footer>small>a{
  color: #EC5717;
}
#contact>div:nth-child(2){
  flex-basis: 40%;
}
.forminput{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: white;
}
.forminput>label{
  font-weight: bolder;
  margin-bottom: -5px;
}
.forminput>input,.forminput>textarea{
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
}
.forminput>input{
  height: 30px;
  padding: 2px;
  font-size: 18px;
}
.forminput>textarea{padding: 6px;}
#contact>div:nth-child(2)>form>button{
  background-color: #EC5717;
  border-radius: 4px;
  height: 40px;
  outline: none;
  border: none;
  color: #100c3e;
  padding: 10px 25px 10px 25px;
  cursor: pointer;
  font-weight: bolder;
}
.footer2{
  display: none;
}
/* END */

/* BAR */
.bar{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: 50px;
}
.bar>div{
  width: 140px;
  height: 6px;
  border-radius: 2px;
}
/* END */
}