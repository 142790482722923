@import "react-alice-carousel/lib/alice-carousel.css";
*{
  font-family: 'Montserrat', sans-serif;
}
.p-0{
  padding: 0!important;
}
.m-0{
  margin: 0!important;
}
.row{
  display: flex;
  flex-direction: row;
}
/* #1A6DFF Icon Blue */
.bold{font-weight: bold!important;}
.bolder{font-weight: bolder!important;}
.t80{ font-size: 80px!important; }
.t70{ font-size: 70px!important; }
.t56{ font-size: 56px!important; }
.t40{ font-size: 40px!important; }
.t36{ font-size: 36px!important; }
.t28{ font-size: 28px!important; }
.t26{ font-size: 26px!important; }
.t24{ font-size: 24px!important; }
.t20{ font-size: 20px!important; }
.t18{ font-size: 18px!important; }
.t16{ font-size: 16px!important; }
.t14{ font-size: 14px!important; }
.t12{ font-size: 12px!important; }
.t10{ font-size: 11px!important; }
body{
  background-color: white;
}
#root{
  width: 100%!important;
  height: 100vh!important;
}
.bg{ color: #22272A!important;}
#header{
  position: fixed;
  width: 100%!important;
}
header{
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center!important;
  box-sizing: border-box;
  z-index: 9999999;
  backdrop-filter: blur(5px)!important;
}
.bg-scroll{
  background-color: rgba(255, 255, 255,0.6);
}
.border{ border: 1px solid red; }
.pointer{cursor: pointer!important;}
.uppercase{text-transform: uppercase!important;}

/* Important For Full Page */
.fp-overflow{
  height: 100%!important;
}

/* Website Color */
.blue-1{ color: #13A4BB!important;}
.blue-2{ color: #18C0FF!important;}
.blue-3{ color: #19D2EE!important;}
.blue-4{ color: #19DFFF!important;}
.wheat{color: #EC5717!important;}
.white{color: white!important;}
.bg-blue-1{ background-color: #13A4BB!important;}
.bg-blue-2{ background-color: #18C0FF!important;}
.bg-blue-3{ background-color: #19D2EE!important;}
.bg-blue-4{ background-color: #19DFFF!important;}
.primary{color: #100c3e!important;}
.bg-primary{background-color: #100c3e!important;}
.secondary{color: #f66860!important;}
.bg-secondary{background-color: #f66860!important;}
.bg-white{background-color: white!important;}
.bg-wheat{background-color: #EC5717!important;}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
/* END */

/* Menu */
#media-control{
  width: 100%;
  height: 12px;
  position: absolute;
  top: 0px;
  z-index: 99999999999999;
}
#menu-list{
  margin-left: 30px;
  transition: opacity 0.5s ease-in-out;
}
#menu-list>ul,#menu-social{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px!important;
  font-weight: 500;
}
#menu-list>ul>li{
  list-style-type: none!important;
  margin: 0px 10px 0px 10px;
}
#menu-list>ul>li>a{
  font-size: 20px;
  font-weight: bold;
  text-decoration: none!important;
}
#anchor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
#anchor>div{
  height: 6px;
  border-radius: 4px;
}
#anchor>div:nth-child(1){
  width: 50px;
  height: 7px;
  margin-bottom: 10px;
  /* border: 1px solid white; */
}
#anchor>div:nth-child(2){
  width: 30px;
  /* border: 1px solid black; */
}
#menu-social>a{
  margin-left: 6px;
}
#menu-social>a>img{
  width: 30px;
  height: 30px;
}
.opac{
  opacity: 0.6!important;
}
/* END */

/* Content Block */
.content-space{
  padding: 60px 80px 0px 80px;
}
.content{
  position: relative;
  height: calc(100% - 60px);
}
/* END */

/* Presentation */
#presentation{
  display: flex;
  flex-direction: row;
  padding: 60px 80px 0px 0px;
  height: calc(100% - 60px)!important;
  background-color: rgba(240, 248, 255,0.6);
  background-image: url('./../public/assets/img/bgsoft.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
#presentation>img:nth-child(1){
  width: auto;
  height: 65%;
  object-fit: cover;
  z-index: 999999;
  position: absolute;
  bottom: 0;
}
#presentation-text{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}
.soft{
  text-align: right;
  line-height: 0.8;
  letter-spacing: -6px;
  margin-right: 6px;
  font-size: 8rem;
}
.soft>span{color: #EC5717;}
#presentation-text>small{font-weight: 600;}
.summary{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  text-align: right;
}
.summary>h1:nth-child(1){font-size: 28px;margin-top: 25px;margin-bottom: 0px;}
.summary>h1:nth-child(2){font-size: 18px;font-weight: 500;}
.p-buts{
  align-self: self-end;
  margin-left: 80px;
  margin-top: 20px;
  align-items: end;
}
.p-buts>button{
  width: 250px;
  cursor: pointer;
  height: auto;
  font-size: 18px;
  font-weight: 500;
  padding: 20px;
  box-sizing: border-box;
  font-weight: bold;
}
.p-buts>button:nth-child(1){
  background-color: #100c3e;
  color: white;
  margin-right: 20px;
  border: none;
}
.p-buts>button:nth-child(2){
  background-color: transparent;
  color: #100c3e;
  border: 2px solid #100c3e;
  margin-left: 20px;
}
/* END */

/* About */
#about{
  display: flex;
  flex-direction: column;
  padding: 60px 40px 0px 40px;
  height: calc(100% - 60px)!important;
  align-items: start;
  background: #282c34;
  justify-content: center;
}
#about>div:nth-child(2){
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}
#labels{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#labels>div{
  height: 90px;
}
.clipath{
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  background: #EC5717;
  width: 200px;
  height: 60px;
  margin-bottom: 15px;
  opacity: 0.5;
}
.clipath:hover{
  opacity: 0.8;
}
.active-clip{
  opacity: 1;
}
#labels>div>h4{
  font-size: 16px;
  position: relative;
  top: -60px;
  color: white;
  margin-left: 60px;
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
}
.ab-text{
  color: white;
  text-align: center;
}
.ab-text>img,.education>img,#skills>img{
  height: 40px;
  position: absolute;
  top: -30px;
  left: 30px;
}
#about-contant{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
#about-contant>div{
  display: flex;
  align-items: center;
}
#about-contant>div:nth-child(1){
  width: 90%;
  height: auto;
  border-radius: 6px;
}
.ab-text{
  font-weight: 400;
  font-size: 20px;
}
.education{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: #EC5717;
  text-align: center;
  width: 100%;
}
.education>h2{
  font-size: 48px;
  text-align: center;
  width: 100%;
}
.education>h2{font-size: 28px;}
.education>small>a>img{
  height: 20px;
}

/* Skills */
#skills{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.sk-txt{
  display: flex;
  justify-content: center;
}
.sk-txt>p{
  font-size: 16px;
  padding: 0px 6px 0px 6px;
  margin: 0;
}
.sk-head{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
}
.sk-head>h4{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #EC5717;
}
.sk-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
/* END */

/* BAR */
.bar{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: 50px;
}
.bar>div{
  width: 140px;
  height: 6px;
  border-radius: 2px;
}
/* END */

/* Project */
#projects{
  display: flex;
  flex-direction: column;
  padding: 60px 80px 0px 80px;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(240, 248, 255,0.6);
  background-image: url('./../public/assets/img/pro&more.svg');
  background-repeat: no-repeat;
  background-position-y: 10%;
  background-position-x: -20px;
  background-size: 35%;
  overflow: hidden;
}
.pro-slide{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 150px;
}
#pcard{
  width: 100%;
  height: 100%;
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat;
}
.card-title{
  font-size: 2.2rem;
  /* margin-right: 15px!important; */
}
.div-title{display: flex;}
.card-logo{
  height: 40px;
  width: auto;
}
.card-desc{
  font-size: 20px;
  margin: 15px 0;
  word-spacing: 2px;
  font-weight: 400;
  width: 90%;
}
.procard{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-right: 40px;
  justify-content: space-around;
}
.card-git{
  position: absolute;
  right: 20px;
  top: 20px;
  height: 40px;
}
.card-tech{
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 10px; */
}
.card-tech>span{
  font-weight: bolder;
  font-size: 1.2rem;
  margin-right: 15px;
  font-weight: 600;
}
.card-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 90%;
}
.card-footer>div:nth-child(1){
  display: flex;
  flex-wrap: nowrap;
}
#card-link{
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  margin-right: 20px;
  font-size: 18px;
}
#card-link>img{
  height: 25px;
}
/* END */

/* Swiper */
.swiper {
  width: 60%;
  height: 60%;
  margin: 0px!important;
  width: 100%!important;
  height: 80%!important;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-button-next,.swiper-button-prev{
  color: white!important;
}
/* END */

/* Contact */
#contact{
  display: flex;
  flex-direction: row;
  padding: 60px 80px 0px 80px;
  justify-content: space-between;
  align-items: center;
  color: #100c3e!important;
  background-image: url('./../public/assets/img/me.svg');
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 50%;
}
.c-text{
  display: flex;
  flex-direction: column;
  color: white;
}
.c-text>h1{
  font-size: 4rem;
}
.footer>small>a{
  color: #EC5717;
}
#contact>div:nth-child(2){
  flex-basis: 40%;
}
.forminput{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: white;
}
.forminput>label{
  font-weight: bolder;
  margin-bottom: -5px;
}
.forminput>textarea{font-size: 18px;}
.forminput>input,.forminput>textarea{
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
}
.forminput>input{
  height: 30px;
  padding: 2px;
  font-size: 18px;
}
.forminput>textarea{padding: 6px;}
#contact>div:nth-child(2)>form>button{
  background-color: #EC5717;
  border-radius: 4px;
  height: 40px;
  outline: none;
  border: none;
  color: #100c3e;
  padding: 10px 25px 10px 25px;
  cursor: pointer;
  font-weight: bolder;
  margin-right: 15px;
}
.footer2{
  display: none;
}
/* END */

/* FULL */
.fp-watermark{
  display: none!important;
}
.fp-watermark>a{
  display: none!important;
}

/* Loading */
#loading{
  height: 40px;
  width: auto;
  position: absolute;
}